<script src="../../../back-manage/vue.config.js"></script>
<template>
    <div class="main">
        <img class="top-img" src="../../assets/image/top-img.png"/>
        <div class="top-text-box">
            <div class="top-text">仁瑞科技</div>
            <div class="top-text"><span class="top-text-color">内推</span>奖金加码</div>
        </div>
        <div class="content-box">
            <div class="content-top">
                震惊！人康集团内推奖金重磅加码！<br/>
                内推 <span style="font-weight: bold">奖金加码</span> 了你还不知道？<br/>
                如果你的亲朋好友有意推荐、自荐，<br/>
                伸出你发财的小手和666，<br/>
                哦不，是大力的投递简历！</div>
            <img class="text-img" src="../../assets/image/666.png"/>
            <div class="button-box">
                <img class="z-img" src="../../assets/image/left-z.png"/>
                <div class="button-center">内推奖金</div>
                <img class="z-img" src="../../assets/image/right-z.png"/>
            </div>
            <div class="reward-box">
                <div class="position-show-title">
                    <span class="position-show-title-icon">.</span>
                    <span class="position-show-title-text">奖励标准</span>
                </div>
                <div class="reward-content">
                    <span>{{nowData.rewardStandard}}</span>
                </div>
            </div>
            <div class="button-box">
                <img class="z-img" src="../../assets/image/left-z.png"/>
                <div class="button-center">热招岗位</div>
                <img class="z-img" src="../../assets/image/right-z.png"/>
            </div>
            <div class="position-box">
                <div v-for="item in nowData.jobNeedList" v-bind:key="item.id" class="position-button">{{item.jobName}}</div>
            </div>
            <div @click="toShare" class="share-button">分享给好友</div>
            <!--<div @click="toShareFriend" class="share-friend-button">分享到朋友圈</div>-->
            <div class="bottom-blank"></div>
        </div>
        <div class="bottom-blank"></div>
    </div>
</template>

<script>
    import { getJobInfo, getUserId, getJsSdkConfig, getWxJsSdkConfig } from '@/api/service'
    import { Toast } from 'vant';
    import Cookies from 'js-cookie'
    const wx = window.wx;
    export default {
        name: 'Reward',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                query: '',
                nowUserInfo: {}, // 当前用户信息
                nowData: '',
                configPostData: {
                    agentId: 1000076,
                    url: ''
                },
            }},
        created() {
            // Cookies.set('userId', 'Wangleiqiang')
            let userId=Cookies.get('userId')
            const query = this.$route.query
            if(!query.taskId){
                Toast('参数缺失')
            }
            this.query = query
            console.log(query)
            if(!userId){
                this.login()
            }else{
                this.userId=userId
            }
            // 获取jssdk 配置的信息
            this.getConfig()
            this.getAllParam()
            this.initShare()
        },
        mounted:function(){

        },
        methods: {
            // 用户网页授权登录
            login(){
                let query2=this.$route.query;
                if(query2.code){
                    let postData={}
                    postData.code=query2.code
                    getUserId(postData).then(response => {
                        Cookies.set('userId', response.data)
                        this.userId=response.data
                    })
                }else{
                    let nowUrl = window.location.href;
                    // let nowUrl = 'https://talent.renkangshangcheng.cn/reward?taskId=' + this.query.taskId
                    let backurl = encodeURIComponent(nowUrl)
                    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                    window.location.href=url
                }
            },
            // 获取sdk  配置
            getConfig() {
                let that = this
                this.configPostData.url = window.location.href
                getJsSdkConfig(this.configPostData).then(response => {
                    console.log(response)
                    that.initAgentConfig(response.data);
                })
            },
            // 初始化 agentConfig
            initAgentConfig(jsSdkConfig) {
                console.log(jsSdkConfig)
                let that = this
                wx.agentConfig({
                    corpid: jsSdkConfig.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                    agentid: jsSdkConfig.agentId, // 必填，企业微信的应用id （e.g. 1000247）
                    timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
                    signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                    jsApiList: ['hideOptionMenu', 'shareWechatMessage','onMenuShareAppMessage','onMenuShareWechat','onMenuShareTimeline','shareAppMessage'], //必填，传入需要使用的接口名称
                    success: function() {
                        // 回调
                        console.log('初始化agent 成功')
                    },
                    fail: function(res) {
                        console.log(res)
                        if(res.errMsg.indexOf('function not exist') > -1){
                            alert('版本过低请升级')
                        }
                    }
                });
                wx.ready(function () {
                    //禁用右上角菜单按钮
                    wx.hideOptionMenu();
                })
            },
            // 格式化分享
            initShare() {
                let that = this
                let postData = {}
                postData.url = window.location.href
                getWxJsSdkConfig(postData).then(response => {
                    that.initConfig(response.data);
                })
            },
            // 初始化 agentConfig
            initConfig(jsSdkConfig) {
                wx.config({
                    debug: false,
                    appId: jsSdkConfig.appId, // 必填，公众号的唯一标识
                    timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jsSdkConfig.noncestr, // 必填，生成签名的随机串
                    signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                    jsApiList: ['hideOptionMenu','updateAppMessageShareData', 'updateTimelineShareData',"onMenuShareTimeline","onMenuShareAppMessage","onMenuShareQQ","onMenuShareWeibo","onMenuShareQZone"], //必填，传入需要使用的接口名称
                });
                wx.ready(function () {
                    //禁用右上角菜单按钮
                    wx.hideOptionMenu();
                    // this.toShareFriend()
                })
            },
            // 分享到微信
            toShare() {
                const postData = {}
                postData.title = this.nowData.shareTitle
                postData.desc = this.nowData.shareIntro
                postData.link = 'https://talent.renruikeji.cn/share?taskId=' +this.nowData.id+ '&shareId=' + this.userId
                postData.imgUrl = 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png'
                console.log(postData)
                wx.invoke(
                    "shareWechatMessage", postData, function(res) {
                        console.log(res)
                        if (res.err_msg == "shareWechatMessage:ok") {
                            Toast('分享成功')
                        }
                    }
                );
            },
            // 分享到朋友圈
            toShareFriend() {
                const postData = {}
                postData.title = this.nowData.shareTitle
                postData.desc = this.nowData.shareIntro
                postData.link = 'https://talent.renruikeji.cn/share?taskId=' +this.nowData.id+ '&shareId=' + this.userId
                postData.imgUrl = 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png'
                console.log(postData)
                wx.onMenuShareTimeline({
                    title: postData.title, // 分享标题
                    link: postData.link, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                    imgUrl: postData.imgUrl, // 分享图标
                    success: function () {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                    }
                });
            },
            // 获取详情
            getAllParam() {
                const postData = {}
                postData.taskId = this.query.taskId
                postData.type = 'pub'
                getJobInfo(postData).then((response) => {
                    console.log(response)
                    this.nowData = response.data
                })
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#1243D5;
        background: url('../../assets/image/bj.jpg')  no-repeat center center fixed ;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .top-img{
        position: absolute;
        right: 0;
        top: 50px;
        width: 100px;
        height: 80px;
    }
    .top-text-box{
        font-size: 45px;
        color: #FFFFFF;
        font-weight: bold;
        padding: 30px 0 0 20px;
    }
    .top-text-color{
        color: #FFBF00;
    }
    .content-box{
        background-color: #ffffff;
        border-radius: 10px;
        width: calc(100vw - 40px);
        margin: 20px 20px 0 20px;
        position: relative;
    }
    .content-top{
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #606166;
        line-height: 20px;
        text-align: center;
    }
    .button-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .z-img{
        width: 20px;
        height: 20px;
    }
    .button-center{
        width: 108px;
        height: 32px;
        background: linear-gradient(90deg, #FFF163 0%, #FFD400 100%);
        border-radius: 11px;
        border: 2px solid;
        border-image: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)) 2 2;
        line-height: 32px;
        text-align: center;
        margin: 0 15px;
        color: #1243D5;
        font-size: 18px;
        font-weight: bold;
    }
    .reward-box{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .reward-title{
        width: 70px;
    }
    .reward-content{
        width: calc(100vw - 80px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        white-space: pre-wrap;
        word-break: break-all;
    }
    .money-color{
        color: #FF9034;
    }
    .position-box{
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        padding: 20px;
    }
    .position-button{
        width: 84px;
        height: 28px;
        line-height: 28px;
        background: #8D74FF;
        border-radius: 14px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 10px;
    }
    .share-button{
        width: 240px;
        height: 44px;
        line-height: 44px;
        background: linear-gradient(310deg, #FF610B 0%, #F19106 61%, #FBCB17 100%);
        border-radius: 22px;
        color: #ffffff;
        text-align: center;
        margin: 0 auto;
    }
    .share-friend-button{
        width: 240px;
        height: 44px;
        line-height: 44px;
        background: linear-gradient(310deg, #FF610B 0%, #F19106 61%, #FBCB17 100%);
        border-radius: 22px;
        color: #ffffff;
        text-align: center;
        margin: 30px auto;
    }
    .bottom-blank{
        width: 100%;
        height: 30px;
    }
    .text-img{
        width: 40px;
        height: 30px;
        position: absolute;
        top: 80px;
        right: 30px;
    }
    .position-show-title{
        height: 50px;
        line-height: 50px;
    }
    .position-show-title-icon{
        width: 3px;
        height: 12px;
        background: #2586FF;
        color: #2586FF;
        margin-right: 10px;
    }
    .position-show-title-text{
        font-size: 16px;
        font-weight: bold;
    }
</style>
